<template>
  <div>
    <ui-modal ref="Modal" v-bind="modalProps" v-on="$listeners">
      <el-form ref="form" :rules="rules"   :model="sendSms" label-width="100px" hide-required-asterisk>
        <el-form-item label="选择模版" prop="template_id" label-width="90px">
          <el-select  placeholder="请选择"  v-model="sendSms.template_id" @change="templatChage" style="width:100%">
            <el-option v-for="item in smsTemplates" :key="item.template_id" :label="item.template_title" :value="item.template_id" :disabled="item.disabled"></el-option>
          </el-select>
          <el-input type="textarea"   readonly :autosize="{ minRows: 3, maxRows: 6 }" v-model="smsTemplatContent"></el-input>
        </el-form-item>
        <el-form-item label="下载模版文件">
          <el-button  @click="getdownload" :disabled="showdownload"  style="width:100%;height:38px"> <i class="el-icon-folder-add"></i>请点击下载手机号模版文件</el-button>
        </el-form-item>
        <el-form-item label="添加联系人" label-width="100px" prop="file">
          <el-upload ref="upload" drag action="123"  accept=".csv" :limit="1" :http-request="test" :before-upload="beforeFileTypeUpload"  :on-exceed="handleExceed">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击<i class="el-icon-folder-opened"></i>上传文件</em></div>
            <div class="el-upload__tip" slot="tip"><i class="el-icon-warning-outline"></i> 仅支持CSV格式，限单个文件，大小在5GB以内</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="短信主题" prop="theme">
          <el-input v-model="sendSms.theme"  maxlength="32" show-word-limit placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="过滤近期发送" prop="filter_condition" class="validation" >
          <el-radio v-model="sendSms.filter_condition" :label="1" :disabled="sendSms.value ?true : false" >近1天</el-radio>
          <el-radio v-model="sendSms.filter_condition" :label="3" :disabled="sendSms.value ?true : false">近3天</el-radio>
          <el-radio v-model="sendSms.filter_condition" :label="7" :disabled="sendSms.value ?true : false">近7天</el-radio>
          <el-input  v-model="sendSms.value" @input="filterday"  style="width:60px"  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>&nbsp;&nbsp;天
        </el-form-item>
        <el-form-item label="过滤已购天数" prop="purchase_time_filter">
          <el-radio v-model="sendSms.purchase_time_filter" :label="0" :disabled="sendSms.value1 ?true : false">不过滤</el-radio>
          <el-radio v-model="sendSms.purchase_time_filter" :label="1" :disabled="sendSms.value1 ?true : false">近1天</el-radio>
          <el-radio v-model="sendSms.purchase_time_filter" :label="3" :disabled="sendSms.value1 ?true : false">近3天</el-radio>
          <el-radio v-model="sendSms.purchase_time_filter" :label="7" :disabled="sendSms.value1 ?true : false">近7天</el-radio>
          <el-input  v-model="sendSms.value1" @input="filterBuy" style="width:60px" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"></el-input>&nbsp;&nbsp;天
        </el-form-item>
        <el-link  class="LinkI" type="info" :underline="false" disable-transitions v-if="fileRemark" >  <i class="el-icon-info"></i> <i>{{fileShowName}}</i>  文件中含 <i> {{ fileLength }} </i>条手机号，检测到<i> {{filePhone}} </i>条无效号码，预计发送人数 <i> {{ people_number }} </i>人，消耗<i> {{ consumption_number}} </i>条短信， 如选择了过滤，以实际发送结果为准。</el-link>
        <div class="enableTime">
          <el-tooltip :content="'是否启用定时 ' + sendSms.isDisabled" placement="top">
            <el-switch v-model="sendSms.isDisabled" 
              active-color="#409EFF" 
              inactive-color="#909399" 
              inactive-value="否" 
              active-value="是"
              @change="changeDisabled">
            </el-switch>
          </el-tooltip>
        <el-form-item label="定时发送" prop="TimeData">
           <div class="block">
            <span class="demonstration"> </span>
            <el-date-picker
              v-model="TimeData"
              :clearable="false"
              :disabled="sendSms.disabled"
              type="datetime"
              placeholder="选择日期时间"
              @change="handler"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOption"
              style="width:40%"
              class="date_picker">
            </el-date-picker>
          </div>
        </el-form-item>
        <div class="attention" v-if="showAttention">
          <p><i class="el-icon-warning-outline"></i>请注意</p>
          <p>1) 如果您启用了定时发送，在该任务未发送完成前对短信模板的任何修改都将影响定时发送任务的实际发送内容；</p>
          <p>2) 如果你不希望发生变更，请在该任务未发送完成前不要对短信模板进行修改或更新设置；</p> 
        </div>
        </div>
      </el-form>
    </ui-modal>
  </div>
</template>
<script>
import {errorAlert,successAlert}from "../../../utils/alert"
import * as XLSX from "xlsx";
export default {
  props: ["row"],
  data() {
    const { row } = this;
    var checkUrl = (rule, value, callback) => {
      if (this.smsTemplatContent.includes("@var(short_url)")) {
        let reg =/^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i;
        if (value === "") {
          callback(new Error("请输入url"));
        } else if (!reg.exec(value)) {
          callback(new Error("请输入正确的url"));
        }
        callback();
      } else {
        callback();
      }
    };
    return {
      filePhone:0,
        lists: [],
      showdownload:true,
      showAttention:false,
      form: row ? { ...row } : {},
      people_number:0,
      consumption_number:0,
      fileLength: 0,
      fileShowName:"",
      fileRemark: false,
      smsTemplatContent: "", 
      modalProps: {
        title: "新建短信任务",
        okText: "提交发送任务",
        width: "60%",
        onSubmit: async () => {
           await this.$refs.form.validate();
          if(!this.smsTemplatContent.includes("@var(short_url)")){
            this.formData.append("template_id", this.sendSms.template_id);
            this.formData.append("theme", this.sendSms.theme);
            this.formData.append("long_url", this.sendSms.long_url);
            this.formData.append("filter_condition",parseInt(this.sendSms.filter_condition))
            this.formData.append("purchase_time_filter",parseInt(this.sendSms.purchase_time_filter))
            this.formData.append("scheduler_params",JSON.stringify(this.sendSms.scheduler_params))
            const res = await this.$http.post("/excel/message/send",this.formData);
             if (res.data.code==0) {    
               successAlert(res.data.msg)
                }else{
                  errorAlert(res.data.msg)
                }
          }else{
            this.formData.append("template_id", this.sendSms.template_id);
            this.formData.append("theme", this.sendSms.theme);
            this.formData.append("filter_condition",parseInt(this.sendSms.filter_condition));
            this.formData.append("purchase_time_filter",parseInt(this.sendSms.purchase_time_filter))
            this.formData.append("scheduler_params",JSON.stringify(this.sendSms.scheduler_params))
            this.$prompt('由于您选中的短信模版中含短网址参数,请输入长链接URL:', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  model:"sendSms.long_url",
                  inputPattern:  /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i,
                  inputErrorMessage: 'URL格式不正确'
            }).then(({ value }) => {
              this.formData.append("long_url", value);
              this.send()
                }).catch(()=>{
                    this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
              });
          }
        },
      },
      smsTemplates: [],
      formData: null,
      value:"",
      value1:'',
      TimeData: null,
      sendSms: {
        isDisabled: false,
        disabled: true,
        file:"",
        theme: "", 
        long_url: "", 
        template_id: "", 
        filter_condition: null, 
        purchase_time_filter:null,
        scheduler_params:{} 
      },
      schedulerArr:{
        scheduler_type:"cron",
        scheduler_year: "",
        scheduler_month:"",
        scheduler_day:"",
        scheduler_hour:"",
        scheduler_minute:"",
        scheduler_second:""
      },
      rules: {
        theme: [{ required: true, message: "请输入营销主题", trigger: "blur" },
               { min: 1, max: 32, message: '字数超过限制，长度在 1到 32 个字符', trigger: 'blur' },],
        template_id: [{ required: true, message: "请选择短信模版" },],
        long_url: [{ validator: checkUrl, trigger: "blur" }],
        filter_condition: [{ required: true, message: '请选择过滤近期发送'}],
        purchase_time_filter: [{ required: true, message: '请选择过滤已购天数'}],
      },
      
      pickerOption: {
        disabledDate(time) {
          return time.getTime() < Date.now() -  8.64e7;
          },
          selectableRange:"00:00:00 - 23:59:59",
        },
    };
  },

  watch:{
    "TimeData":{
      handler(newValue, oldValue){
         if (newValue) {
            let date = new Date();//当前时间
            let min = date.getMinutes();//当前时间的分
            date.setMinutes(min);
            let nowDate = this.$moment(date).format("HH:mm:ss");//当前时分秒
            let st = "";
            if (this.$moment(date).format("yyyy-MM-DD") === this.$moment(newValue).format("yyyy-MM-DD")) {
              let hh1 = this.$moment(newValue).format("HH:mm:ss")
              if(hh1 <= nowDate) {
               this.TimeData = this.$moment(date).format("yyyy-MM-DD HH:mm:ss");
              }
              st = nowDate;
            } else {
              st = "00:00:00";
            }
            this.pickerOption.selectableRange = st + "-23:59:59";
            this.pickerOption = this.pickerOption;
          }
      },
         deep: true,
        immediate: true,
    },
  },

  created() {
    this.getSmsTemplates();
  },

  methods: {

    handler(val){
      var dayArray = val.split(/[\s:-]/);
      this.schedulerArr.scheduler_year = dayArray[0]
      this.schedulerArr.scheduler_month = dayArray[1]
      this.schedulerArr.scheduler_day = dayArray[2]
      this.schedulerArr.scheduler_hour = dayArray[3]
      this.schedulerArr.scheduler_minute = dayArray[4]
      this.schedulerArr.scheduler_second = dayArray[5]
      this.sendSms.scheduler_params = this.schedulerArr
    },
    
    changeDisabled(val){
      if(val=="是"){
        this.sendSms.disabled = false;
        this.modalProps.okText="提交定时任务"
        this.showAttention=true
      }else{
        this.sendSms.disabled = true;
        this.modalProps.okText="提交发送任务"
        this.showAttention=false;
        this.TimeData="";
        this.sendSms.scheduler_params={}
      }
    },
      
    filterday(val){
      this.sendSms.filter_condition=val
    },
    
    filterBuy(val){
      this.sendSms.purchase_time_filter=val
    },
     
    async send(){
      const res =  await this.$http.post("/excel/message/send",this.formData)
        if(res.data.code ==0){
          successAlert(res.data.msg)
        }else{
          errorAlert(res.data.msg)
        }
    },

    test() {},

    templatChage(row) {
      this.smsTemplates.forEach((item) => {
        if (item.template_id === row) {
          this.smsTemplatContent = item.template_content;
        }
      });
      if( this.smsTemplatContent == ""){
          this.showdownload=true
        }else{
            this.showdownload=false
        }
    },

    beforeFileTypeUpload(file) {
      this.fileShowName=file.name;
      const isOk = file.name.split(".")[1] === "csv";
      if (!isOk) {
        this.$message({
          message: "附件格式错误，请删除后重新上传!",
          type: "error",
        });
      }
      if (file.size > 5 * (1024 * 1000)) {
        this.$message({
          message: "上传文件大小不能超过5G!",
          type: "error",
        });
      }
      this.formData = new FormData();
      this.formData.append("file", file);
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
            let lists = [];
            ws.forEach(item => {
             lists.push({
                phone:item["phone"],
                });
              });
            let isPhone=[];
            let reg=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
             lists.forEach(item=>{
              if(!reg.test(item.phone)){
                isPhone.push({
                  phone:item["phone"],
                })
              }
            })
          this.fileLength = ws.length;
          this.filePhone=isPhone.length
          this.people_number= this.fileLength-this.filePhone
          this.consumption_number=this.fileLength-this.filePhone
          this.fileRemark = true;
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
    
     handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，如需更换请先清空已经上传的文件,再次进行上传`
      );
    },
    
    async getSmsTemplates() {
      const res = await this.$http.get("/message_template/list");
      this.smsTemplates = res.data.data.templates;
        this.smsTemplates.forEach((item) => {
          if(item.template_status != "2"){
            item.disabled = true;
          }
        })
    },
   
    async getdownload() {
      this.$export({
        fileName: "手机号模版.csv",
          action: () =>
            this.$http.get(
              `/template/download?template_id=${this.sendSms.template_id}`,{responseType: "blob",}
            ),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input--small .el-input__icon {
    margin-top: 6px;
}
/deep/.el-form-item__label {
    margin-top: 6px;
}
/deep/.el-form-item--small .el-form-item__label {
    line-height: 36px;
}
/deep/.el-input .el-input__count .el-input__count-inner {
    line-height: 0;
    display: inline-block;
    padding: 0px 5px;
    padding-top: 16px !important;
}
.el-radio{
  margin-right: 10%;
}
.el-divider{
  cursor: pointer;
}
/deep/.el-upload__tip {
  color: #999;
  font-size: 12px;
  font-weight: 300;
  margin-top: -10px;
}
/deep/.el-upload-dragger {
  
  .el-upload__text {
    font-size: 12px;
  }
}
.el-upload-dragger .el-icon-upload {
    font-size: 35px;
    color: #C0C4CC;
    margin: -10px 0 -5px; 
    line-height: 50px;
}
/deep/.el-form-item__label {
  margin-top: 10px;
  font-size: 14px;
}
/deep/.el-input--small .el-input__inner {
  margin-top: 10px;
}
/deep/.el-radio {
  margin-top: 20px;
}
/deep/.el-link {
  margin-left: 20px;
  margin-right: 10px;
  color: #909399;
}
/deep/ .el-upload{
  width: 100%;
  height: 80px;
}
/deep/ .el-upload .el-upload-dragger{
  width: 100%;
  height:70px;
}
.LinkI{
  i{
  color: #67C23A;
}
}
.enableTime{
  border-top: 1px solid #F2F6FC;
  position: relative;
  /deep/.el-switch__core{
    width: 30px !important;
    height: 15px !important;
   
  }
  /deep/.el-switch__core:after {
    content: "";
    position: absolute;
    top: 1px;
    border-radius: 100%;
    transition: all .3s;
    width: 12px !important;
    height: 11px !important;
}
/deep/.el-switch.is-checked .el-switch__core::after {
    margin-left: -13px !important;
}
.el-switch{
  top: 38px;
  left: -7px;
  }
}
.attention{
  background: #cae1ff;
  border:1px solid #F2F6FC;
  color: #409EFF;
  font-size: 12px;
  font-weight: 350;
 
  box-sizing:border-box;
  padding-left: 20px;
  border-radius: 10px;
  p{
     line-height: 16px;
  } 
}
/deep/ .el-form-item.is-error  .el-input__inner{
        border-color: #EBEEF5;
      } 

</style>
